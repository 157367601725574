import React from "react";
import { StaticQuery, graphql } from "gatsby";
import ProjectSlider from "components/ProjectSlider";

const WorkFeaturedList = () => (
	<StaticQuery
		query={graphql`
		{
			allMarkdownRemark(
				filter: {frontmatter: {featured: {eq: true}, published: {eq: true}}}
				sort: {fields: frontmatter___priority, order: ASC}
				limit: 4
			) {
				edges {
					node {
						id
						frontmatter {
							priority
							featured
							published
							slug
							title
							website
							app
							colours
							tags
							client
							slides
						}
					}
				}
			}
		}
		`}
		render={(data) => (
			<>
				{data.allMarkdownRemark.edges.map(({ node }) => (
					<ProjectSlider
						title={node.frontmatter.title}
						slides={node.frontmatter.slides}
						colours={node.frontmatter.colours}
						client={node.frontmatter.client}
						tags={node.frontmatter.tags}
						featured={node.frontmatter.featured}
						website={node.frontmatter.website}
						app={node.frontmatter.app}
					/>
				))}
			</>
		)}
	/>
);

export default WorkFeaturedList;
