import React from "react";
import { Link } from "gatsby";
import ReactRotatingText from "react-rotating-text";
import Page from "components/Page";
import SeoComponent from "components/SEO";
import ColourSection from "components/ColourSection";
import Header from "components/Header";
import Footer from "components/Footer";
import Logo from "components/Logo";
import Flex from "components/Flex";
import Columns from "components/Columns";
import Section from "components/Section";
import WorkFeaturedList from "components/WorkFeaturedList";
import FadeIn from "components/FadeIn";
import LogoGrid from "components/LogoGrid";

const Home = ({ location }) => (
	<>
		<SeoComponent location={location} />

		<Page
			minHeight="90vh"
			middle={(
				<FadeIn>
					<h1>
						We are Mothership
						<br />
						We
						{" "}
						<ReactRotatingText items={["design", "develop", "launch", "maintain", "innovate"]} randomise="true" eraseMode="overwrite" color="var(--accent-color)" />
						user-led
						{" "}
						<ReactRotatingText items={["digital experiences", "digital products", "digital services", "SaaS", "apps", "websites"]} randomise="true" eraseMode="overwrite" color="var(--accent-color)" />
					</h1>
					<span>
						We help our clients to innovate, achieve their business
						goals and exceed their customer’s expectations. We do this
						by creating engaging and effective digital products, services,
						brand experiences and websites.
					</span>
				</FadeIn>
			)}
		/>

		<Page
			id="more"
			top={(
				<Flex
					flexDirection="row"
					alignItems="flex-start"
					justifyContent="space-between"
				>
					<h4>Featured work</h4>
					<Link to="/work" style={{ float: "right" }}>View all work</Link>
				</Flex>
			)}
			middle={<WorkFeaturedList />}
			bottom={(
				<Flex
					flexDirection="row"
					alignItems="flex-start"
					justifyContent="flex-end"
				>
					<Link to="/work" style={{ float: "right" }}>View all work</Link>
				</Flex>
			)}
		/>

		<ColourSection backgroundColor="var(--accent-color-light)">
			<Page
				top={(
					<FadeIn>
						<h1>
							We
							{" "}
							<ReactRotatingText items={["think", "solve", "create", "build", "deliver"]} randomise="true" color="var(--accent-color)" />
						</h1>
						<p>
							We help businesses to convert their ideas into successful mobile
							applications and websites through a comprehensive range of digital
							services and creative skills -
							<b>
								delivering high-quality, digital product
								experiences with proven business value that users love.
							</b>
						</p>
					</FadeIn>
				)}
				middle={(
					<FadeIn>

						<Section>
							<Columns>
								<div className="column">
									<h2>Strategy</h2>
									{/* <p>
										Ask The Right Question: We take the time to ask the right questions and
										dig until we get answers to the core of the problem. Our methodology is based
										on human-centred research and proven design thinking methods. Then we
										recommend action steps that lead to measurably better results.</p> */}
									<ul>
										<FadeIn random><li><Link to="/services">Brief development</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Scoping exercises</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">UI/UX strategy</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Research</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Product management</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">+</Link></li></FadeIn>
									</ul>
								</div>
								<div className="column">
									<h2>Design</h2>
									<ul>
										<FadeIn random><li><Link to="/services">Concept design</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">UI/UX design</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Branding</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Design style guides</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Content creation</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">+</Link></li></FadeIn>
									</ul>
								</div>
								<div className="column">
									<h2>Development</h2>
									<ul>
										<FadeIn random><li><Link to="/services">Prototyping</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">SaaS</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">React.js (cross-platform)</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">iOS and Android native</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">Front/Back-end</Link></li></FadeIn>
										<FadeIn random><li><Link to="/services">+</Link></li></FadeIn>
									</ul>
								</div>
							</Columns>
						</Section>
					</FadeIn>
				)}
				bottom={<Link to="/services">+ Find out more...</Link>}
			/>
		</ColourSection>

		<Page
			top={(
				<>
					<FadeIn>
						<h1>We are ...</h1>
					</FadeIn>
					<FadeIn>
						<Logo fluid />
					</FadeIn>
				</>
			)}
			middle={(
				<FadeIn>
					<Columns>
						<div className="column">
							<h2>
								Design &
								<br />
								{" "}
								development done right.
							</h2>
							<p>
								Mothership is an experienced software development and UX/UI design agency based
								in the UK and Barcelona, offering design thinking-based and technology-driven
								services for our clients and partners worldwide since 2010.
							</p>
							<p>
								Mothership is a start-to-end design and app development agency. We develop
								high-quality, intuitive, engaging and scalable apps, providing design and
								development services for every stage of your project.
							</p>
							<p>
								We specialise in solving business challenges with creative design and the latest
								technology to create experiences users love and products with proven business value.
							</p>
						</div>
						<div className="column">
							<h2>
								A flexible &
								<br />
								{" "}
								adaptive approach.
							</h2>
							<p>
								We have the knowledge and experience to work across the board
								– with startups and SMEs to global brands. And, with a broad and
								deep skillset, our clients get the benefit of our proven cross-sector
								insight and experience.
								{" "}
							</p>
							<p>
								Our proven track record is delivered by our small and agile team,
								which allows us to be responsive and personal in our service.
							</p>
							<p>
								We don’t restrict ourselves to work in specific sectors. And, although
								we do have deeper insights and experience in pharmaceutical and veterinary
								medicine, we love the diversity of working across multiple industries –
								encountering new ideas and opportunities has a knock-on benefit for all our clients.
							</p>
							<b>At Mothership, we genuinely care.</b>
						</div>
					</Columns>
				</FadeIn>
			)}
			bottom={null}
		/>

		<Page
			top={(
				<FadeIn>
					<h4>Clients</h4>
					<p>
						Our expertise and experience spans Travel and Leisure, Health &amp;
						Fitness, Healthcare, Pharmaceutical, high-end Audio Manufacture, Finance,
						Payroll Management, Veterinary, SaaS, Developer tools, Food &amp; Beverage,
						Mobile &amp; Tech industries, Broadcast… and much more.
					</p>
				</FadeIn>
			)}
			middle={(
				<FadeIn>
					<LogoGrid />
				</FadeIn>
			)}
			bottom={null}
		/>

		<Footer />
		<Header />

	</>
);

export default Home;
