import React from "react";
import styled from "styled-components";
import Flex from "./Flex";

const StyledComponent = styled.div`
	margin-top: 6rem;
	margin-bottom: 6rem;
`;

const Section = ({ children }) => (
	<StyledComponent>
		<Flex flexDirection="column" padding="0" width="100%" height="100%">
			{children}
		</Flex>
	</StyledComponent>
);

export default Section;
