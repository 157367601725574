import React from "react";
import styled from "styled-components";
import FadeIn from "components/FadeIn";

const StyledComponent = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-gap: 2rem;
	> div {
		display: grid;
		place-items: center;
		&::before {
			content: "";
			display: block;
			padding-bottom: 30%;
			grid-area: 1 / 1 / 2 / 2;
		}
		img {
			width: 60px;
			filter: grayscale(1);

			@media (prefers-color-scheme: dark) {
				filter: grayscale(1) invert(1);
			}
		}
	}
`;

const LogoGrid = () => (
	<StyledComponent>
		<div><FadeIn random><img src="/images/logos/adp.svg" alt="ADP" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/ExxonMobil_Logo.svg" alt="ExxonMobile" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/jdp.svg" alt="JDP" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/naim.svg" alt="Naim" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/ouno.svg" alt="Ouno" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/social-noise.svg" alt="Social Noise" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/total.svg" alt="Total" /></FadeIn></div>
		<div><FadeIn random><img src="/images/logos/vpop.svg" alt="vPOP PRO" /></FadeIn></div>
	</StyledComponent>
);

export default LogoGrid;
