import React from "react";
import { InView } from "react-intersection-observer";

const FadeIn = (props) => (
	<InView
		{...props}
		threshold={0.3}
	>
		{({ inView, ref }) => (
			<div ref={ref} className={`animated ${inView ? "fadeIn" : "hidden"}`} style={{ display: "inline-block", transitionDelay: props.random ? `${200 + Math.random() * 700}ms` : "200ms" }}>
				{props.children}
			</div>
		)}
	</InView>
);

export default FadeIn;
