import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SeoComponent = ({
	title, description, keywords, image, article, location
}) => {
	const { pathname } = location;
	const { site } = useStaticQuery(query);

	const {
		defaultTitle,
		titleTemplate,
		defaultDescription,
		defaultKeywords,
		siteUrl,
		defaultImage,
		twitterUsername,
	} = site.siteMetadata;

	const seoTitle = title ?? defaultTitle;
	const seoDescription = description ?? defaultDescription;
	const imagePath = `${siteUrl}${image || defaultImage}`;
	const urlPath = `${siteUrl}${pathname}`;

	return (
		<Helmet title={seoTitle} titleTemplate={titleTemplate}>
			<meta name="description" content={seoDescription} />
			<meta name="keywords" content={keywords ?? defaultKeywords} />
			<meta name="image" content={imagePath} />

			{urlPath ? <meta property="og:url" content={urlPath} /> : null}

			{(article ? true : null) && <meta property="og:type" content="article" />}

			{seoTitle ? <meta property="og:title" content={seoTitle} /> : null}

			{seoDescription
				? <meta property="og:description" content={seoDescription} />
				: null }

			{imagePath ? <meta property="og:image" content={imagePath} /> : null}

			<meta name="twitter:card" content="summary_large_image" />

			{twitterUsername ? <meta name="twitter:creator" content={twitterUsername} /> : null}

			{seoTitle ? <meta name="twitter:title" content={seoTitle} /> : null}

			{seoDescription
				? <meta name="twitter:description" content={seoDescription} />
				: null }

			{imagePath ? <meta name="twitter:image" content={imagePath} /> : null}
		</Helmet>
	);
};

export default SeoComponent;

SeoComponent.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	keywords: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
	location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

SeoComponent.defaultProps = {
	title: null,
	description: null,
	keywords: null,
	image: null,
	article: false,
};

const query = graphql`
	query SEO {
		site {
			siteMetadata {
			defaultTitle: title
			titleTemplate
			defaultDescription: description
			defaultKeywords: keywords
			siteUrl: url
			defaultImage: image
			twitterUsername
			}
		}
	}
`;
